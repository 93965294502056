import React from 'react';

const AboutUs: React.FC = () => {
    return (
        <section className="py-12 bg-black text-green-500 font-orbitron">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap items-center">
                    <div className="lg:w-1/2 px-4 mb-8 lg:mb-0">
                        <h2 className="text-3xl font-bold text-white mb-4 font-orbitron">About Brockliens</h2>
                        <p className="text-green-500 mb-4 font-orbitron">
                            Brockliens is a unique collection of NFTs—diverse digital collectibles living on the Bitrock blockchain. With an artistic nod to extraterrestrial life, each Brocklien is algorithmically generated, boasting distinct traits and characteristics. Our spacefaring NFTs serve not just as art but also as a passport to an evolving digital ecosystem.
                        </p>
                        <p className="text-green-500 font-orbitron">
                            We're on a mission to merge creativity with blockchain technology, offering an inclusive for collectors. The Brockliens community thrives on the values of connectivity, innovation, and intergalactic camaraderie. We aim to lead by example, showcasing the potential of NFTs to revolutionize digital ownership and community-building within the cosmos of the web.
                        </p>
                    </div>
                    <div className="lg:w-1/2 px-4">
                        <img src="/images/logo1.png" alt="Brockliens NFT collection on Bitrock" className="rounded-lg shadow-lg" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
