import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faReact,
  faHtml5,
  faPython,
  faJsSquare,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';

const FounderSection: React.FC = () => {
  const skillsIcons = [
    { icon: faReact, label: 'React' },
    // ... Add placeholders or custom SVGs for other skills
    { icon: faJsSquare, label: 'JavaScript' },
    { icon: faHtml5, label: 'HTML' },
    { icon: faPython, label: 'Python' },
  
    { icon: faGithub, label: 'GitHub' },
  ];

  return (
    <section className="relative z-10 pt-12 font-orbitron">
      <div className="container mx-auto px-4 py-8 flex flex-col items-center justify-center">
        <div></div>
        <div className="backdrop-blur-sm rounded-lg shadow-xl p-6 w-full md:w-auto text-center">
          {/* Image */}
          <div className="mb-4">
            <img
              src="/images/22.jpg"
              alt="Architect"
              className="w-32 h-32 md:w-48 md:h-48 object-cover rounded-lg border-4 border-green-500 mx-auto"
            />
          </div>
          {/* Title */}
          <h2 className="text-2xl md:text-4xl font-bold text-green-500 mb-2">Architect</h2>
          {/* Name and Twitter icon */}
          <div className="mb-4 inline-block">
            <span className="text-md md:text-lg text-yellow-500"><span className="text-green-500 font-bold">//</span> DAave MITT</span>
            <a
              href="https://twitter.com/daavemitt"
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-500 ml-2 hover:text-white"
            >
              <FontAwesomeIcon icon={faXTwitter} size="1x" />
            </a>
          </div>
          {/* Skills icons */}
          <div className="flex justify-center flex-wrap">
            {skillsIcons.map((skill, index) => (
              <div key={index} className="p-2">
                <FontAwesomeIcon icon={skill.icon} size="3x" title={skill.label} className="text-white hover:text-green-500" />
              </div>
              
            ))}
          </div>
          <div className="flex justify-center flex-wrap">
          <a href="" aria-label="Your Link Title">
            <img src="images/blender.png" alt="Your Link Title" className="h-6 w-6  text-white hover:text-green-500" /> {/* Set the size with height and width classes */}
          </a>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default FounderSection;
