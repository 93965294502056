import React, { useState, useEffect } from 'react';
import '../styles/HeroBanner.css'; // Import your stylesheet


const HeroBanner: React.FC = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Apply the large screen class conditionally
  const heroBannerClass = isLargeScreen ? 'hero-banner-large' : '';

  return (
    <div className={`relative bg-cover bg-center md:bg-[length:50%_auto] text-green-500 ${heroBannerClass}`}
      style={{
        backgroundImage: 'url(/images/testing13.svg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover', // This will be overridden by the CSS class on large screens
        backgroundRepeat: 'no-repeat',
      }}>
      <div className="bg-opacity-10 bg-black flex items-center justify-center h-[75vh]">
        {/* Dark backdrop behind text */}
        <div className="text-center bg-black bg-opacity-50 p-5 rounded-lg">
         <h1 className="font-orbitron text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-bold uppercase text-green-500">BROCKLIENS</h1>
         <p className="font-orbitron mt-4 text-sm sm:text-lg md:text-xl text-white">WE'RE AMONG YOU</p>
        </div>
      </div>


    </div>
  );
};

export default HeroBanner;
