import React, { useEffect, useState } from 'react';
import '../styles/CountdownTimer.css';

const CountdownTimer: React.FC<{ targetDate: Date }> = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date();
    let timeLeft: { [key: string]: number } = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const convertToBinaryWithErrors = (number: number) => {
    // Convert number to a binary string
    let binaryString = number.toString(2);
    // Pad the binary string so that it is 8 bits long
    binaryString = binaryString.padStart(2, '0');
    // Introduce errors by flipping some of the bits
    return binaryString.split('').map((bit, index) => {
      // Randomly decide whether to flip the bit or not
      if (Math.random() < 0.1) { // 10% chance to flip a bit
        return bit === '0' ? '1' : '0';
      }
      return bit;
    }).join('');
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="bg-black text-green-500 p-4 rounded-lg shadow-md font-orbitron">
      <div className="flex justify-center items-center text-4xl space-x-3">
        
        <span className="spooky-text">{convertToBinaryWithErrors(timeLeft.hours)}</span>
        <span className="spooky-text">{convertToBinaryWithErrors(timeLeft.minutes)}</span>
        <span className="spooky-text">{convertToBinaryWithErrors(timeLeft.seconds)}</span>
      </div>
    </div>
  );
};

export default CountdownTimer;
