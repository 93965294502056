// src/components/Products.tsx
import React, { useState } from 'react';
import { products } from '../data/products';
import FiltersSidebar from './FiltersSidebar';
import { Product } from '../types';

const Products: React.FC = () => {
    const [filters, setFilters] = useState({
        category: '',
        color: '',
        material: '',
    });

    // Use Array.from() to create an array from the Set of unique categories
    const uniqueCategories = Array.from(new Set(products.map(product => product.category || 'Unknown')));

    const setFilter = (filterType: string, value: string) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterType]: value === 'All' ? '' : value,
        }));
    };

    const filteredProducts = products.filter(product =>
        Object.entries(filters).every(([key, value]) =>
            !value || product[key as keyof Product] === value
        )
    );

    return (
        <div className="container mx-auto p-4 flex font-orbitron">
            <FiltersSidebar setFilter={setFilter} />
            <div className="flex-1 pl-4">
                <div className="mb-4 flex flex-wrap justify-center">
                    {['All', ...uniqueCategories].map(category => (
                        <button
                            key={category}
                            onClick={() => setFilter('category', category)}
                            className={`m-2 px-4 py-2 border rounded ${filters.category === category || (category === 'All' && filters.category === '') ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
                        >
                            {category}
                        </button>
                    ))}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {filteredProducts.map(product => (
                        <div key={product.id} className="border rounded-lg p-4 shadow-lg">
                            <img src={product.image} alt={product.name} className="rounded mb-4 w-full h-auto" />
                            <h3 className="text-xl font-semibold mb-2">{product.name}</h3>
                            <p>{product.price}</p>
                            {/* <a href={`/product/${product.id}`} className="text-blue-600 hover:text-blue-800">View NFTs</a> */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Products;

