// src/data/products.js
export const products = [
    {
      id: 1,
      category: 'Alien',
      name: 'Landing soon',
      price: '$BROCK',
      image: '/images/logo1.png',
      images: ['/images/nft1.png', '/images/nft1.png'], // Multiple images
      pdf: '/pdfs/classic-outdoor-wall-light-spec.pdf', // PDF spec sheet
    // ... other product fields ...
      color: 'Black',
      material: 'Metal',
      finish: 'Matte',
      width: '6 inches',
      height: '12 inches',
      style: 'Traditional',
      lumens: '800lm',
      bulbType: 'LED',
      description: 'Testingggggggggggggggg'
    },
    {
      id: 2,
      category: 'Attributes',
      name: 'Landing soon',
      price: '$BROCK',
      image: '/images/logo1.png',
      color: 'Silver',
      material: 'Aluminum',
      finish: 'Polished',
      width: '8 inches',
      height: '18 inches',
      style: 'Modern',
      lumens: '1000lm',
      bulbType: 'Halogen',
    },
    {
      id: 3,
      category: 'Eyes',
      name: 'Landing soon',
      price: '$BROCK',
      image: '/images/logo1.png',
      color: 'Bronze',
      material: 'Plastic',
      finish: 'Brushed',
      width: '4 inches',
      height: '10 inches',
      style: 'Rustic',
      lumens: '450lm',
      bulbType: 'Incandescent',
    },
    // ... Add the rest of your products with similar properties
  ];
  
  // Make sure to adjust the properties according to the actual details of your products.
  

// // src/data/products.js
// export const products = [
//     { id: 1, category: 'Outdoor Wall Lights', name: 'Classic Outdoor Wall Light', price: '$49.99', image: '/images/landscape-light.jpg' },
//     { id: 2, category: 'Post Lights', name: 'Modern Post Light', price: '$89.99' },
//     { id: 3, category: 'Pathway Lights', name: 'Garden Pathway Light', price: '$29.99' },
//     { id: 4, category: 'Landscape Lighting', name: 'LED Landscape Spotlight', price: '$39.99' },
//     { id: 5, category: 'Step Lighting', name: 'Step Safety Light', price: '$19.99' },
//     { id: 6, category: 'Landscape Accessories', name: 'Landscape Lighting Cable', price: '$15.99' },
//     { id: 7, category: 'Outdoor String Lights', name: 'Festive String Lights', price: '$24.99' },
//     // You can add more products as needed
//   ];
  