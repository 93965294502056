import React from 'react';
import BentoBox from './BentoBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faMicrochip } from '@fortawesome/free-solid-svg-icons';

const BentoLayout: React.FC = () => {
  // This array would eventually be dynamic, fetched from an API or defined elsewhere
  const bentoItems = [
    {
      icon: <FontAwesomeIcon icon={faBolt} className="text-xl" />,
      title: 'Pending Assure KYC',
      detail: '-loading-',
      backgroundColor: 'bg-purple-500'
    },
    {
      icon: <FontAwesomeIcon icon={faMicrochip} className="text-xl" />,
      title: 'Collaberate',
      detail: 'Seeking KYC Collabs',
      backgroundColor: 'bg-green-500'
    },
    {
        icon: <FontAwesomeIcon icon={faMicrochip} className="text-xl" />,
        title: 'Community',
        detail: 'Join a growing community',
        backgroundColor: 'bg-purple-500'
      },
      {
        icon: <FontAwesomeIcon icon={faMicrochip} className="text-xl" />,
        title: 'Team',
        detail: 'Join us',
        backgroundColor: 'bg-green-500'
      },
    // Add more items as needed
  ];

  return (
    <div className="bg-gray-900 py-8 font-orbitron">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {bentoItems.map((item, index) => (
            <BentoBox key={index} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BentoLayout;
