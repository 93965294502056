import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import '../index.css';


interface FlipCardProps {
    company: Company;
  }

  
  const FlipCard: React.FC<FlipCardProps> = ({ company }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandClick = () => {
      setIsExpanded(!isExpanded);

    }
  
    return (
      <div className={`flip-card-container relative w-full h-40 cursor-pointer font-orbitron text-green-500`}
           onMouseEnter={() => setIsFlipped(true)}
           onMouseLeave={() => setIsFlipped(false)}>
        <div className={`flip-card w-full h-full z-0 ${isFlipped ? 'flipped' : ''}`}>


            
          {/* Front of the card */}
          <div className="front absolute w-full h-full bg-black/60 text-green-500 rounded-lg shadow-md flex flex-col items-center justify-center">
            <img src={company.logo} alt={`${company.name} logo`} className="w-20 h-20 mb-2" />
            <p className="font-semibold">{company.name}</p>
          </div>
  
          {/* Back of the card */}
          <div className="back absolute inset-0 bg-green-500 rounded-lg shadow-md flex flex-col items-center justify-between p-4 transform rotate-y-180">
  
           {/* Company Info */}
             <div className="text-center">
              <h3 className="text-white text-lg font-bold">{company.name}</h3>
              <p className="text-white text-sm">{company.description}</p>
            </div>
  
            {/* Social Links */}
            <div className="flex items-center space-x-4 mb-2">
              <a href={company.url} target="_blank" rel="noopener noreferrer" className="text-white text-lg">
                <FontAwesomeIcon icon={faGlobe} />
              </a>
              {company.twitterUrl && (
                <a href={company.twitterUrl} target="_blank" rel="noopener noreferrer" className="text-white text-lg">
                  <FontAwesomeIcon icon={faXTwitter} />
                </a>
              )}
            </div>
  
        

          </div>
        </div>
      </div>
   
    );
  };



interface Company {
  name: string;
  description?: string;
  url: string;
  logo?: string;
  twitterUrl?: string;
  seo?: string;
}

interface EcosystemSectionProps {
  title: string;
  companies: Company[];
}




const EcosystemSection: React.FC<EcosystemSectionProps> = ({ title, companies }) => {
    return (
      <div className="p-8 px-20">
        <h2 className="text-2xl font-bold text-green-500 mb-4">{title}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {companies.map((company, index) => (
            <FlipCard key={index} company={company} />
          ))}
        </div>
      </div>
    );
  };
  

const EcosystemLayout: React.FC = () => {
  const ecosystemSections: { [key: string]: Company[] } = {
    'Bitrock Network': [
      {
        name: "Bitrock",
        description: "Discover Bitrock Network _ your gateway to a revolutionary blockchain platform...",
        url: "https://www.bit-rock.io/",
        logo: "/images/BitrockLogo2.png",
        twitterUrl: "https://twitter.com/BitRockChain",
        seo: `Discover Bitrock Network _ your gateway to a revolutionary blockchain platform where speed, security, and sustainability converge. Designed for the modern cryptocurrency enthusiast, Bitrock Network stands at the forefront of blockchain innovation, offering an environment ripe for decentralized finance (DeFi) solutions, NFT marketplaces, and scalable DApp development. Harness the power of Bitrock's cutting-edge technology that promises lightning-fast transactions without compromising on security... (Include the rest of your SEO content here)`,
      },
      {
        "name": "Bitrock Hackathon",
        "description": "Join the Bitrock Hackathon: A Forge for Blockchain Innovators",
        "url": "https://hackathon.bit-rock.io/",
        "logo": "/images/BitrockLogo2.png",
        "seo": "Gear up for the Bitrock Hackathon, an exhilarating month-long journey from April 15 to May 14, where developers and innovators converge to sculpt the future of blockchain. Register by May 7th to cement your place among the best. Fueled by the Bitrock Chain's near-zero gas fees and lightning-fast block times, our hackathon is the battleground for the brightest minds in Web3, AI, dApps, gaming, NFTs, and cross-chain technologies. With a total prize pool that tantalizes the senses and categories designed to push the boundaries of digital possibility, Bitrock Hackathon is more than a competition—it's a launchpad to the cosmos of innovation. Don't just build, but connect, create, and catapult your project to stardom under the banner of Bitrock. Your solution could be the next cornerstone of our thriving ecosystem, which boasts a token builder, stable coin, multichain swaps, and a dynamic NFT marketplace. Ready to disrupt the norm and pioneer the new era of digital excellence? Join the Bitrock Hackathon."
      }
    ],
    'Tools': [
      { 
        name: "Bitrock Bridge", 
        description: "For bridging assets across the network", 
        url: "https://bridge.bit-rock.io/",
        logo: "/images/bridge_logo.svg",
    },
      { 
        name: "Bitrock Wallet", 
        description: "Defi Wallet", 
        url: "https://bitrockwallet.com/",
        logo: "/images/brw2.png",
        twitterUrl: "https://twitter.com/BitrockWallet",      
    },
    { 
        name: "DEXView", 
        url: "https://www.dexview.com/bitrock",
        logo: "/images/rect1.svg", 
    },
    ],
    'Defi': [
      { 
        name: "Rockswap", 
        description: "DEX", 
        url: "https://rock-swap.io/#/swap",
        logo: "/images/rockswap.webp", 
    },
      { 
        name: "Wrapped Platform", 
        description: "NFT Marketplace", 
        url: "https://wrappedplatform.com/",
        logo: "/images/wrapped.svg",
     }
    ],
    'Verification': [
      { 
        name: "Assure Defi", 
        url: "https://assuredefi.com/",
        logo: "images/Verified-Seal.png" ,
    }
    ],
    // Add other sections if needed
  };

  return (
    <div className="text-green-500 font-orbitron">
      {Object.entries(ecosystemSections).map(([title, companies], index) => (
        <EcosystemSection key={index} title={title} companies={companies} />
      ))}
      <div className="text-black text-center bg-white/60">
          *Message us if you wish to have your project added and/or removed from above*
        </div>
    </div>
  );
};

export default EcosystemLayout;
