import React from 'react';

interface BentoBoxProps {
  icon: JSX.Element; // This assumes you'll be using an SVG or FontAwesome icon
  title: string;
  detail: string;
  backgroundColor: string; // This could be a Tailwind color class
}

const BentoBox: React.FC<BentoBoxProps> = ({ icon, title, detail, backgroundColor }) => {
  return (
    <div className={`p-4 ${backgroundColor} rounded-lg shadow-md m-2 flex flex-col items-center justify-center text-center`}>
      {icon}
      <h2 className="text-lg font-bold mt-2">{title}</h2>
      <p className="text-sm">{detail}</p>
    </div>
  );
};

export default BentoBox;
