// src/components/Roadmap.tsx
import React from 'react';
import '../styles/Roadmap.css';
import SpinningSvg from './SpinningSvg';

interface RoadmapPhase {
  title: string;
  tasks: string[];
}

const phases: RoadmapPhase[] = [

  {
    title: "Phase 1",
    tasks: ["Website Deployment", "Team Building & Legitimacy", "Community building", "Assure KYC", "Join Hackathon"],
  },
  {
    title: "Phase 2",
    tasks: ["", " ", "", "", ""]
  },

  {
    title: "Phase 3",
    tasks: [" ", "  ", " ", " ", " "],
  },
  {
    title: "Phase 4",
    tasks: ["  ", " ", " ", " "]
  },
  {
    title: "Phase 5",
    tasks: ["   ", " "],
  },
];



const BentoBox: React.FC<{ title: string; tasks: string[]; borderColorClass: string }> = ({ title, tasks, borderColorClass }) => {
  const isPhaseRevealed = title === "Phase 1";
  
  return (
    <div className={`relative p-6 border-4 rounded-lg shadow-md bg-black m-4 ${borderColorClass}`}>
      <h3 className="text-xl font-semibold text-green-500 mb-3">{title}</h3>
      {isPhaseRevealed ? (
        <ul className="space-y-2">
          {tasks.map((task, index) => (
            <li key={index} className="text-green-500 text-opacity-75">{task}</li>
          ))}
        </ul>
      ) : (
        <div className="flex justify-center items-center h-full">
          <SpinningSvg />
        </div>
      )}
    </div>
  );
};

const Roadmap: React.FC = () => {
  const borderColorClasses = [
    'border-green-500', // For Phase 1
    'border-blue-100', // For Phase 2
    'border-yellow-500', // For Phase 3
    'border-yellow-500', // For Phase 4
    'border-yellow-500', // For Phase 5
  ];

  return (
    <div className="relative min-h-screen font-orbitron"> {/* Ensures that this div is positioned relative to the video and has at least the screen height */}
      {/* Video Background */}
      <div className="fixed top-0 left-0 w-full h-full z-[-1]"> {/* Sends the video to the back */}
        <video autoPlay loop muted className="object-cover w-full h-full">
          <source src="/images/galaxy3.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Content */}
      <div className="z-10 relative pt-12"> {/* Makes sure content is over the video, padding for any nav/header */}
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-10 text-green-500">Roadmap</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {phases.map((phase, index) => (
              <BentoBox
                key={index}
                title={phase.title}
                tasks={phase.tasks}
                borderColorClass={borderColorClasses[index]}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;