import React from 'react';
import { Link } from 'react-router-dom'; // Import Link component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faMediumM } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-green-500 mt-8 font-orbitron">
      <div className="container mx-auto px-4 py-8">
        {/* Responsive Flex Container */}
        <div className="flex flex-col items-center space-y-4 sm:space-y-0 sm:flex-row sm:justify-between mb-4">
          {/* Powered by BitRock */}
          <div className="text-center">
          <Link to="/" className="text-xl font-bold">BROCKLIENS</Link>
            <p className="text-gray-400">POWERED BY BITROCK</p>
          </div>
          {/* Social Media Icons */}
          <div className="flex space-x-4">
            {/* ...social media icons */}
            {/* Social Media Icons */}
          <div className="flex justify-end space-x-4">
            <a href="https://twitter.com/brockliens" aria-label="Twitter">
              <FontAwesomeIcon icon={faXTwitter as IconProp} />
            </a>
            <a href="https://medium.com/@brockliens" aria-label="Medium">
              <FontAwesomeIcon icon={faMediumM as IconProp} />
            </a>
            {/* <a href="https://github.com/yourprofile" aria-label="GitHub">
              <FontAwesomeIcon icon={faGithub as IconProp} />
            </a> */}
            {/* <a href="" aria-label="Discord" className="text-yellow-500">
              <FontAwesomeIcon icon={faDiscord as IconProp} />
            </a>
            <a href="" aria-label="Telegram" className="text-yellow-500">
              <FontAwesomeIcon icon={faTelegramPlane as IconProp} /> */}
            {/* </a> */}
            {/* <a href="mailto:info@brockliens.io" aria-label="Email">
              <FontAwesomeIcon icon={"envelope" as IconProp} />
            </a> */}
          </div>

          </div>
        </div>

    
        <div className="flex justify-center items-center w-full px-4">
         <a href='https://assuredefi.com/projects/brockliens/'>
          <img src="/images/AssureDefiLogo.svg" alt="Brockliens Bitrock Assure Defi" className="rounded-lg shadow-lg" />
          </a>
        </div>

        {/* Centered Copyright Text */}
        <div className="text-center">
          <p className="text-green-500 text-sm">info@brockliens.io</p>
          <p className="text-gray-400 text-sm">
            © {new Date().getFullYear()} MADE WITH
            <FontAwesomeIcon icon={faHeart} className="text-green-500 mx-1" />
            BY BROCKLIENS. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;