import React from 'react';
import HeroBanner from './HeroBanner';
import AboutUs from './AboutUs';
import VideoSection from './VideoSection';
import Footer from './Footer';
import Contact from './Contact';
import Products from './Products';
import NFTMinting from './NFTMinting';
import Roadmap from './Roadmap';
import CountdownTimer from './CountdownTimer';
import FounderSection from './FounderSection';
import BitrockEcosystem from './BitrockEcosystem';
import BentoLayout from './BentoLayout';
import BentoLayout2 from './BentoLayout2';
import EcosystemLayout from './EcosystemLayout';

const featuredProducts = [
    { id: 1, name: 'Landing Soon', price: '$BROCK', image: '/images/logo1.png', category: 'Brocklien' },
    { id: 2, name: 'Landing Soon', price: '$BROCK', image: '/images/logo1.png', category: 'Brocklien2' },
    { id: 2, name: 'Landing on the Bitrock Network May 2024', price: 'BITROCK HACKATHON', image: '/images/NavbarAssure3.svg', category: 'Brocklien2' },
    // Add more featured products as needed
];



const Home: React.FC = () => {
    // Function to handle when a product is clicked
    const targetDate = new Date('2024-12-31T23:59:59');
    const handleProductClick = (category: string) => {
        // Implement functionality for when a product is clicked
        // For example, you might set the state with the selected product category
        // and display a modal with the product details
        console.log(`Category: ${category}`);
    };


    return (
        <main>
            {/* Hero Banner Section */}
            <HeroBanner />
            <CountdownTimer targetDate={targetDate}/>
            {/* <NFTMinting /> */}
            
            <AboutUs />
            {/* <VideoSection /> */}
            <div>
                <Roadmap />
            </div>
            {/* <Roadmap /> */}
            

            {/* Featured Items Section */}
            <section className="py-12 bg-black font-orbitron  text-green-500">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold text-green-500 mb-6 font-orbitron">Brocklien Attributes</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                        {featuredProducts.map(product => (
                            <div key={product.id} className="border rounded-lg p-4 shadow-lg">
                                <img src={product.image} alt={product.name} className="rounded mb-4 w-full h-auto" />
                                <h3 className="text-xl font-semibold mb-2">{product.name}</h3>
                                <p>{product.price}</p>
                            </div>
                        ))}
                    </div>

                  


                </div>
            </section>
         
            
            <EcosystemLayout />

            {/* Our Products Section */}
            {/* The button here can be a scroll anchor if you have a full products list further down on the page */}
            <section className="py-12 bg-black text-green-500">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold mb-6">Explore NFTs</h2>
                    <p className="mb-8">
                        Learn more about the Bitliens ecosystem.
                    </p>
                    <button
                        onClick={() => {/* Implement functionality to show all products */ }}
                        className="bg-blue-600 font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
                    >
                        View NFTs
                    </button>
                </div>
                <Products />
                
                {/* <Contact /> */}
            </section>
            <FounderSection />
            <BentoLayout />

            {/* Footer Section */}
            <Footer />
        </main>
    );
};

export default Home;
