import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link component
import WalletConnect from './WalletConnect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faMedium } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';

const Navbar: React.FC = () => {
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);

  // Categories would be used to navigate to parts of the page or to filter the list of products
  const categories = [
    { name: 'View All', query: 'all' },
    { name: 'Aliens', query: 'outdoor-wall-lights' },
    // ...other categories
  ];

  // Function to scroll to an element on the page
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    section?.scrollIntoView({ behavior: 'smooth' });
    setIsProductsDropdownOpen(false); // Close the dropdown after click
  };

  return (
    <nav className="bg-black text-green-500 p-4 font-orbitron">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-xl font-bold">BROCKLIENS</Link>
        <div className="flex space-x-4 items-center relative">
          {/* SEO Page Link */}
          <Link to="/Content" className="text-xl hover:text-gray-400 transition duration-300">
            Bitrock
          </Link>
          {/* Assuming you have a 'products' section on your page */}
          {/* <a href="#products" className="hover:text-gray-400 transition duration-300">
            NFTs
              </a> */}

              



              <a href="https://twitter.com/brockliens" aria-label="Twitter" className="hover:text-gray-400 transition duration-300">
              <FontAwesomeIcon icon={faXTwitter as IconProp} size="lg" />
              </a>
              <a href="https://medium.com/@brockliens" aria-label="Medium" className="hover:text-gray-400 transition duration-300">
              <FontAwesomeIcon icon={faMedium as IconProp} size="lg" />
              </a>




          {/* Categories dropdown */}
          <div className="relative">
            {/* <button
              onClick={() => setIsProductsDropdownOpen(!isProductsDropdownOpen)}
              className="hover:text-gray-400 transition duration-300"
            >
              ▼
            </button> */}
            {isProductsDropdownOpen && (
              <div 
                onMouseLeave={() => setIsProductsDropdownOpen(false)}
                className="absolute mt-1 py-2 w-48 bg-white rounded-md shadow-xl z-10"
              >
                {categories.map(({ name, query }) => (
                  <a
                    key={name}
                    href={`#${query}`}
                    onClick={() => scrollToSection(query)}
                    className="block px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white w-full text-left"
                  >
                    {name}
                  </a>
                ))}

               
                
                
              </div>
            )}
          </div>
          {/* Links to sections of your single page */}
          {/* <a href="#about-us" className="hover:text-gray-400 transition duration-300">About Us</a>
          <a href="#contact" className="hover:text-gray-400 transition duration-300">Contact</a> */}
          {/* <WalletConnect /> */}




        </div>
      </div>
    </nav>
  );
};

export default Navbar;
