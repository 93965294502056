import React from 'react';

const SpinningSvg: React.FC = () => {
    return (
      <div className="animate-spin3d h-40 w-40">
        <img 
          src="/images/ImagePlusTextWhite.svg" 
          alt="Spinning SVG" 
          className="h-full w-full object-contain"
        />
      </div>
    );
  };

export default SpinningSvg;
