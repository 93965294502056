// src/components/FiltersSidebar.tsx
import React from 'react';

interface FiltersSidebarProps {
    setFilter: (filterType: string, value: string) => void;
}

const FiltersSidebar: React.FC<FiltersSidebarProps> = ({ setFilter }) => {
    // Example of setting filters based on provided categories
    // In a real scenario, you might want to dynamically generate these from your products dataset
    const categories = ['All', 'Alien', 'Eyes', 'More'];
    const colors = ['All', 'Black', 'White', 'Silver'];
    const materials = ['All', '', '', ''];

    const handleFilterChange = (filterType: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter(filterType, event.target.value === 'All' ? '' : event.target.value);
    };

    return (
        <aside className="w-1/4 border-r border-gray-200 pr-4">
            <h2 className="font-bold text-lg mb-4">Filters</h2>
            {/* Category Filter */}
            <div className="mb-4">
                <label htmlFor="category-filter" className="font-semibold">Category</label>
                <select id="category-filter" onChange={handleFilterChange('category')} className="block w-full p-2 border border-gray-300 rounded mt-2">
                    {categories.map((category) => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
            </div>
            {/* Color Filter */}
            <div className="mb-4">
                <label htmlFor="color-filter" className="font-semibold">Color</label>
                <select id="color-filter" onChange={handleFilterChange('color')} className="block w-full p-2 border border-gray-300 rounded mt-2">
                    {colors.map((color) => (
                        <option key={color} value={color}>{color}</option>
                    ))}
                </select>
            </div>
            {/* Material Filter */}
            <div className="mb-4">
                <label htmlFor="material-filter" className="font-semibold">Material</label>
                <select id="material-filter" onChange={handleFilterChange('material')} className="block w-full p-2 border border-gray-300 rounded mt-2">
                    {materials.map((material) => (
                        <option key={material} value={material}>{material}</option>
                    ))}
                </select>
            </div>
        </aside>
    );
};

export default FiltersSidebar;



// // src/components/FiltersSidebar.tsx
// import React from 'react';

// // Assuming a specific subset of Product properties for filtering
// interface FilterState {
//   category?: string;
//   color?: string;
//   material?: string;
// }

// // Props definition using the FilterState interface
// interface FiltersSidebarProps {
//   filters: FilterState;
//   setFilter: (filterType: keyof FilterState, value: string) => void;
// }

// const FiltersSidebar: React.FC<FiltersSidebarProps> = ({ filters, setFilter }) => {
//   // Implementation for rendering the filter options
//   return (
//     <aside className="w-1/4 border-r border-gray-200 pr-4">
//       <h2 className="font-bold text-lg mb-4">Filters</h2>
//       {/* Category filter dropdown */}
//       <div>
//         <h3 className="font-semibold mb-2">Category</h3>
//         <select
//           className="block w-full p-2 border border-gray-300 rounded mb-4"
//           value={filters.category || ''}
//           onChange={(e) => setFilter('category', e.target.value)}
//         >
//           <option value="">All Categories</option>
//           {/* Map over your categories here */}
//         </select>
//       </div>
//       {/* Repeat for other filters like color and material */}
//     </aside>
//   );
// };

// export default FiltersSidebar;




// // src/components/FiltersSidebar.tsx
// import React from 'react';
// import { FilterKeys } from '../types'; // Updated import path

// interface FiltersSidebarProps {
//   filters: Partial<Record<FilterKeys, string>>;
//   setFilter: (filterType: FilterKeys, value: string) => void;
// }



// const FiltersSidebar: React.FC<FiltersSidebarProps> = ({ filters, setFilter }) => {
//   // Example categories, colors, and materials for filters
//   // Ideally, these should be dynamically generated based on available product data
//   const categories = ['Outdoor Wall Lights', 'Post Lights', 'Pathway Lights', 'Landscape Lighting', 'Step Lighting', 'Landscape Accessories', 'Outdoor String Lights'];
//   const colors = ['Black', 'Silver', 'Bronze'];
//   const materials = ['Metal', 'Aluminum', 'Plastic'];

//   return (
//     <aside className="w-1/4 border-r border-gray-200 pr-4">
//       <h2 className="font-bold text-lg mb-4">Filters</h2>
//       {/* Example filter for categories */}
//       <div>
//         <h3 className="font-semibold mb-2">Categories</h3>
//         <ul>
//           {categories.map(category => (
//             <li key={category}>
//               <button onClick={() => setFilter('category', category)} className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100 w-full text-left">
//                 {category}
//               </button>
//             </li>
//           ))}
//         </ul>
//       </div>
//       {/* Implement similar UI for colors, materials, and other filters */}
//     </aside>
//   );
// };

// export default FiltersSidebar;
